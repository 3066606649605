import styled from "styled-components";
import { motion } from "framer-motion";
import {
  variant,
  space,
  color,
  flexbox,
  layout,
  position,
  shadow,
  justifyContent,
} from "styled-system";

const modalVariants = {
  initial: {
    x: -300,
    opacity:0,
    transition: {
      type: "tween",
      duration: 0.5
    }

  },
  animate:{
    x:0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.5,
      staggerChildren: 0.5
      
    }

  },
  exit: {
    x:-300,
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.5,
      
    },
  },
}



export const StyledModal = styled(motion.div).attrs(({open}) => ({
  variants: modalVariants,
  initial: "initial",
  animate: "animate", 
  exit: "exit",
}))(
  position,
  layout,
  space,
  flexbox,
  color,
  shadow,

  variant({
    variants: {
      sidebar: {
        width: "300px",
        backgroundColor: "mobileNavBg",
        minHeight: "100vh",
        boxShadow: "secondary",
      },
    },
  }),

);

StyledModal.defaultProps = {
  position: "fixed",
  zIndex: 99,
};
