import React from "react";
import Slide from "../../elements/Slide/slide";
import { Grid, Row, Col } from "../../elements/Layout";
import { Text, Headline, Button } from "../../elements/index";


const Slider = (props) => {
  const { slides, title, excerpt, cta } = props;

  return (
    <Grid>
      <Row>
        <Col xs flex alignItems="center">
          <Headline textAlign="center" h={2} type="h2" heading={title} />
        </Col>
      </Row>
      <Row center="xs">
        <Col xs mb={{xs: 0}}>
          <Text mb="0" mx="auto" textAlign="center" blocks={excerpt} />
        </Col>
      </Row>
      <Row center="xs" flex justifyContent="start">
        <Col xs>
          {cta && cta.title && cta.iconAnimation && (
            <Button
              {...cta}
              Animation={cta.iconAnimation}
              icon={cta.icon}
              iconPosition={cta.iconPosition}
              link={cta.link}
              title={cta.title}
              mx="auto"
              mb="5"
            />
          )}
        </Col>
      </Row>
      <Row center="xs">
        <Col xs>
          <Slide slides={slides} {...props} />
        </Col>
      </Row>
    </Grid>
  );
};

export default Slider;
