import React, { useRef } from "react";
import { StyledModal } from "./Modal.styled";
import { useClickAway } from "ahooks";

const Modal = (props) => {
  const { children, open, variant, setOpen } = props;

  const modalRef = useRef();
 const isClickedOutside =  useClickAway(() => {setOpen(false)}, modalRef)

  return (
    <StyledModal ref={modalRef} open={open} variant={variant}>
      {children}
    </StyledModal>
  );
};

export default Modal;
