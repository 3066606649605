import { configResponsive } from "ahooks";

const colors = {
  // Brand Colors
  primary: "#28B17D",
  accent: "#10B981",
  success: "#28B17D",
  warning: "#DF556C",
  secondary: "#3B3B3B",
  background: "#ffffff",
  dark: "#090c0c",
  light: "rgba(255, 255, 255, 0.93)",
  transparent: "transparent",
  shadow: "20px 20px 60px #52525247",
  greenShadow: "20px 50px 60px #107e4ee0",
  darkShadow: "20px 50px 60px rgba(12, 12, 12, 0.295)",
  lights: {
    50: "rgba(255, 255, 255, 0.05)",
    100: "rgba(255, 255, 255, 0.10)",
    200: "rgba(255, 255, 255, 0.20)",
    300: "rgba(255, 255, 255, 0.30)",
    400: "rgba(255, 255, 255, 0.40)",
    500: "rgba(255, 255, 255, 0.50)",
    600: "rgba(255, 255, 255, 0.60)",
    700: "rgba(255, 255, 255, 0.70)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.90)",
  },
  darks: {
    50: "rgba(0,0,0, 0.05)",
    100: "rgba(0,0,0, 0.10)",
    200: "rgba(0,0,0, 0.20)",
    300: "rgba(0,0,0, 0.30)",
    400: "rgba(0,0,0, 0.40)",
    500: "rgba(0,0,0, 0.50)",
    600: "rgba(0,0,0, 0.60)",
    700: "rgba(0,0,0, 0.70)",
    800: "rgba(0,0,0, 0.80)",
    900: "rgba(0,0,0, 0.90)",
  },

  greens: {
    50: "#f6fdfa",
    100: "#e7f6f0",
    200: "#b7e5d3",
    300: "#87d3b6",
    400: "#57c299",
    500: "#28b17d",
    600: "#1f8961",
    700: "#166245",
    800: "#0d3b29",
    900: "#04130d",
  },

  greys: {
    50: "#F7F7F7",
    100: "#E1E1E1",
    200: "#CFCFCF",
    300: "#B1B1B1",
    400: "#9E9E9E",
    500: "#7E7E7E",
    600: "#626262",
    700: "#515151",
    800: "#3B3B3B",
    900: "#1F1D20",
  },
};

const theme = {
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  fontSizes: {
    xs: 1 / 1.953 + "rem",
    small: 1 / 1.563 + "rem",
    medium: 1 / 1.25 + "rem",
    large: 1 + "rem",
    h5: 1.25 + "rem",
    h4: 1.563 + "rem",
    h3: 1.953 + "rem",
    h2: 2.441 + "rem",
    h1: 3.052 + "rem",
  },

  breakpoints: ["0em", "30em", "48em", "62em", "80em", "96em"],
  space: [0, 4, 8, 16, 32, 48, 64, 128, 256, 512],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },
  fonts: {
    body: "Nunito, sans-serif",
    title: "Work Sans, sans-serif",
    subtitle: "Dosis, sans-serif",
  },
  borders: [0, "1px solid", "2px solid", "4px solid", "8px solid", "16px solid", "32px solid"],
  radii: [0, 2, 4, 16, 9999, "100%"],
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],

  colors: {
    black: "#000",
    white: "#fff",
    white50: "rgba(255, 255, 255, .2)",
    warning: colors.warning,
    primary: colors.primary,

    // new colors

    // General
    bg: colors.background,
    primaryBG: colors.background,
    secondaryBG: colors.primary,
    ternaryBG: colors.greys[900],

    // headings
    h1: colors.secondary,
    h2: colors.primary,
    h3: colors.secondary,
    primaryHeading: colors.primary,
    secondaryHeading: colors.light,
    ternaryHeading: colors.greys[700],

    // link (default)
    link: colors.secondary,

    // Navigation
    navFont: colors.secondary,
    navHoverEffect: colors.primary,
    navBg: colors.background,
    navBgFirefox: colors.background,
    hamburger: colors.secondary,

    // Footer Nav
    footerColorPrimary: colors.greys[700],
    footerColorSecondary: colors.light,
    footerColorTernary: colors.light,

    footerHoverColorPrimary: colors.primary,
    footerHoverColorSecondary:colors.light,
    footerHoverColorTernary:colors.primary,
    

    // Navigation Mobile
    mobileNavFont: colors.light,
    mobileNavBg: colors.primary,
    mobileNavBgFirefox: colors.primary,

    // logo
    logo: colors.primary,
    secondaryLogo: colors.light,
    // typeWriter
    typeWriterCursor: colors.primary,
    typeWriterFont: colors.primary,

    //Icon
    primaryIcon: colors.primary,
    secondaryIcon: colors.light,
    ternaryIcon: colors.lights[700],
    ternaryIconHover: colors.primary,

    // Text (bodytext)
    text: colors.greys[700],
    primaryText: colors.primary,
    secondaryText: colors.light,
    ternaryText: colors.greys[700],

    // Employees
    primaryEmployeeCardBg: colors.primary,
    primaryEmployeeCardColor: colors.greys[700],
    jobDescriptionBg: colors.light,

    // contactDetails
    primaryCdFontColor: colors.primary,
    secondaryCdFontColor: colors.light,
    ternaryCdFontColor: colors.greys[700],

    // Buttons
    primaryBtnBg: colors.primary,
    primaryBtnBorder: colors.primary,
    primaryBtnFont: colors.light,
    primaryBtnFontHover: colors.primary,
    primaryBtnBorderHover: colors.primary,
    primaryBtnBgHover: colors.transparent,

    secondaryBtnBg: colors.transparent,
    secondaryBtnBorder: colors.light,
    secondaryBtnFont: colors.light,
    secondaryBtnFontHover: colors.primary,
    secondaryBtnBorderHover: colors.light,
    secondaryBtnBgHover: colors.light,

    // Buttons Disabled
    primaryColorDisabled: "",
    secondaryColorDisabled: "",
    ternaryColorDisabled: colors.lights[700],

    // Borders:
    primaryBorder: colors.primary,
    secondaryBorder: colors.light,
    ternaryBorder: colors.greys[700],

    // labels
    primaryLabel: colors.primary,
    secondaryLabel: colors.light,
    ternaryLabel: colors.greys[700],

    // Accordion FAQ
    primaryAccordionQuestion: colors.primary,
    secondaryAccordionQuestion: colors.lights[700],
    ternaryAccordionQuestion: colors.greys[700],
  },

  inputs: {
    primary: {
      color: colors.primary,
    },
    secondary: {
      color: colors.lights[700],
      "&:focus": {
        borderColor: colors.black,
      },
      "&:hover": {
        borderColor: colors.primary,
      },
      "&:-webkit-autofill, &:-webkit-autofill:focus": {
        borderColor: "gray",
        color: "rgba(255,255,255,0.70)",
        "-webkit-text-fill-color": "rgba(255,255,255,0.70)",
        caretColor: "rgba(255,255,255,0.70)",
        "-webkit-box-shadow": "0 0 0px 1000px #222222 inset",
        transition: "background-color 5000s ease-in-out 0s, border .4s ease-out",
      },

      "&:-webkit-autofill:hover": {
        borderColor: colors.primary,
      },
    },
    ternary: {
      color: colors.greys[700],
    },

    warning: {
      borderColor: colors.sucess,
    },

    success: {
      borderColor: colors.primary,
    },
  },

  shadows: {
    primary: colors.shadow,
    secondary: colors.darkShadow,
  },

  // layout

  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 2, // rem
    outerMargin: 2, // rem
    mediaQuery: "only screen",
    container: {
      sm: 54, // rem
      md: 65, // rem
      lg: 76, // rem
      xl: 96
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 78.125, // em
      xl: 96
    },
  },
};

// breakpoint aliases
theme.breakpoints.xs = theme.breakpoints[0];
theme.breakpoints.sm = theme.breakpoints[1];
theme.breakpoints.md = theme.breakpoints[2];
theme.breakpoints.lg = theme.breakpoints[3];
theme.breakpoints.xl = theme.breakpoints[4];
theme.breakpoints.xxl = theme.breakpoints[5];

configResponsive({
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1250,
});

export default theme;

// breakpoints: ["0em", "30em", "48em", "62em", "80em", "96em"],