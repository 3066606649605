import React from "react";
import { StyledBtn, StyledTitle, StyledLink, StyledExternalLink, SmoothLink, BtnWrapper } from "./Button.styled";
import { navigate, Link as GatsbyLink } from "gatsby";
import Icon from "../../elements/Icon/Icon";
import { Link as SmoothLink1 } from 'react-scroll';

const doNavigate = (target) => {
  if (!target || !target.length) {
    return;
  }
  const internal = /^\/(?!\/)/.test(target);
  if (internal) {
    navigate(target);
  } else {
    window.location = target;
  }
};

const Link = ({ to, children, variant, display }) => {
  return (
    <StyledLink display={display} variant={variant}>
      <GatsbyLink to={to}>{children}</GatsbyLink>
    </StyledLink>
  );
};

const ExternalLink = ({ to, children, variant, display }) => {
  return <StyledExternalLink display={display}  variant={variant} href={to}>{children}</StyledExternalLink>;
};

export default function Button(props) {
  const {
    link,
    title,
    icon,
    iconPosition,
    Animation,
    route,
    landingPageRoute,
    kind,
    btnVariant,
    display
  } = props;

  let pageLink = route || link || "#";
  if (landingPageRoute && landingPageRoute.slug && landingPageRoute.slug.current) {
    pageLink = "/" + landingPageRoute.slug.current + "/";
  }


  if (kind === "button") {
    return (
      <>
        <StyledBtn
          display={display}
          onClick={() => doNavigate(pageLink)}
          variant={btnVariant}
          {...props}
        
        >
          {icon && <Icon icon={icon} iconPosition={iconPosition} animation={Animation} />}
          {title && <StyledTitle>{title}</StyledTitle>}
        </StyledBtn>
      </>
    );
  }

  if (link) {
    return (
      <>
        <ExternalLink to={link} variant={btnVariant} display={display}>
          {icon && <Icon icon={icon} iconPosition={iconPosition} animation={Animation} />}
          {title && <StyledTitle>{title}</StyledTitle>}
        </ExternalLink>
      </>
    );
  }

  if (kind ===  "smooth") {
    return (
      <BtnWrapper>
        <SmoothLink to="contact" variant={btnVariant}  display={display} spy={true} smooth={true} duration={1000}>
          {icon && <Icon icon={icon} iconPosition={iconPosition} animation={Animation} />}
          {title && <StyledTitle>{title}</StyledTitle>}
        </SmoothLink>
      </BtnWrapper>
    );
  }



  return (
    <>
      <Link to={pageLink} variant={btnVariant} display={display}>
        {icon && <Icon icon={icon} iconPosition={iconPosition} animation={Animation} />}
        {title && <StyledTitle>{title}</StyledTitle>}
      </Link>
    </>
  );
}
